import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  salesPersonData: {},
  errors: {}
};

export const slice = createSlice({
  name: "salesPerson",
  initialState,
  reducers: {
    clearSalesPersonData: () => initialState,
    setSalesPersonLoading: (state, action) => {
      state.isLoading = true;
    },
    setSalesPersonData: (state, action) => {
      state.isLoading = false;
      state.salesPersonData = action.payload;
    },
    setSalesPersonError: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload;
    },
    clearSalesPersonError: (state) => {
      state.isLoading = false;
      state.errors = {};
    },
  },
});

export const { clearSalesPersonData,
  setSalesPersonLoading,
  setSalesPersonData,
  setSalesPersonError,
  clearSalesPersonError } = slice.actions;

export default slice.reducer;