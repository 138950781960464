import React from "react";
import { Outlet,Navigate } from "react-router-dom";

import logo from "../assets/styles/images/logo-grey.svg"

const Error404 = React.lazy(() => import("../components/errors/error404"));

function CompanyLayout() {
     /**
 * Data retrieved from the Redux store for token role
 * @type {any}
 */
    const token = localStorage.getItem("admin_token") || null;
    const company_id =localStorage.getItem("company_id")||null
    const authority =localStorage.getItem("authority")||null


  return (
    <> 
    {token&& (authority== 1 || authority== 4)?(
       
        <div id="wrapper" className="d-flex flex-column min-vh-100 grey_bgs">
            <div id="content-wrapper">
              <div id="content">
                <div className="container-fluid header-enterprise">
                  <div className="row">
                    <div className="col-md-6 col-pd">
                   {authority != 1 ?<h1 className="heading2">企業</h1>
                     :<h1 className="heading2">3分間コーチ</h1>}
                    </div>
                    <div className="col-md-6 text-right col-logo">
                      <img src={logo} />
                    </div>
                  </div>
                </div>
    
                {/* <div className="container-fluid "> */}
    
                    <Outlet/>
                {/* </div> */}
              </div>
            </div>
          </div>
      

            
    ):(token&& authority==2?
        (<Navigate to={"/companies/" + company_id + "/workshops"} replace />):(!token?(<Navigate to={"/login" } replace />):(<Error404/>))
    )
    }
    </>
  );
}

export default CompanyLayout;
