import React from "react";
import AdminSalesPersonDetailsLayout from "../layouts/admin_salesPersonDetailsLayout";

const SalesPersonDetails = React.lazy(() => import("../pages/salesPerson/salesPersonDetailsPage"));
const AdminDetails = React.lazy(() => import("../pages/admin/adminDetailsPage"))

/**
 * Array of route objects defining the routes for the company-related pages.
 */
export const adminAndSalesAccountsDetailsRoutes = [
  {
    path: "", // The base path for the company routes
    element: <AdminSalesPersonDetailsLayout />, // Renders Layout component for all child routes
    children: [
      {
        path: "/companies/sales-members/:user_id",
        element: <SalesPersonDetails />,
      },
      {
        path: "/companies/admin/:user_id",
        element: <AdminDetails />, 
      },
    ],
  },
];
