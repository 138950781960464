import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const Error404 = React.lazy(() => import("../components/errors/error404"));

function AdminSalesPersonDetailsLayout() {
  /**
* Data retrieved from the Redux store for token role
* @type {any}
*/
  const token = localStorage.getItem("admin_token") || null;
  const company_id = localStorage.getItem("company_id") || null
  const authority = localStorage.getItem("authority") || null


  return (
    <>
      {token && authority == 1 &&
        <div id="wrapper" className="d-flex flex-column min-vh-100 grey_bgs">
          <div id="content-wrapper">
            <div id="content">
              <Outlet />
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default AdminSalesPersonDetailsLayout;