import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  adminDetails: {},
  errors: {}
};

export const slice = createSlice({
  name: "adminDetails",
  initialState,
  reducers: {
    clearAdminDetails: () => initialState,
    setAdminDetailsLoading: (state, action) => {
      state.isLoading = true;
    },
    setAdminDetails: (state, action) => {
      state.isLoading = false;
      state.adminDetails = action.payload;
    },
    setAdminDetailsError: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload;
    },
    clearAdminDetailsError: (state) => {
      state.isLoading = false;
      state.errors = {};
    },
  },
});

export const { clearAdminDetails,
  setAdminDetailsLoading,
  setAdminDetails,
  setAdminDetailsError,
  clearAdminDetailsError } = slice.actions;

export default slice.reducer;