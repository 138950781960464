import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  adminData: {},
  errors: {}
};

export const slice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    clearAdminData: () => initialState,
    setAdminLoading: (state, action) => {
      state.isLoading = true;
    },
    setAdminData: (state, action) => {
      state.isLoading = false;
      state.adminData = action.payload;
    },
    setAdminError: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload;
    },
    clearAdminError: (state) => {
      state.isLoading = false;
      state.errors = {};
    },
  },
});

export const { clearAdminData,
  setAdminLoading,
  setAdminData,
  setAdminError,
  clearAdminError } = slice.actions;

export default slice.reducer;