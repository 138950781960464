import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  salesPersonDetails: {},
  errors: {}
};

export const slice = createSlice({
  name: "salesPersonDetails",
  initialState,
  reducers: {
    clearSalesPersonDetails: () => initialState,
    setSalesPersonDetailsLoading: (state, action) => {
      state.isLoading = true;
    },
    setSalesPersonDetails: (state, action) => {
      state.isLoading = false;
      state.salesPersonDetails = action.payload;
      console.log("Payload,compay", action.payload)
    },
    setSalesPersonDetailsError: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload;
    },
    clearSalesPersonDetailsError: (state) => {
      state.isLoading = false;
      state.errors = {};
    },
  },
});

export const { clearSalesPersonDetails,
  setSalesPersonDetailsLoading,
  setSalesPersonDetails,
  setSalesPersonDetailsError,
  clearSalesPersonDetailsError } = slice.actions;

export default slice.reducer;