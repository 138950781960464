import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { appRoutes } from "./appRouter";
import { authRoutes } from "./authenticationRouter";
import { companyRoutes } from "./companyRouter";
import {participantRoutes} from "./participantRouter"
import { adminAndSalesAccountsDetailsRoutes } from "./AdminAndSalesDetailRouter";

const Error404 = React.lazy(() => import("../components/errors/error404"));

/**
 * Array of route objects defining the routes for the application.
 */
export const routes = [
  {
    path: "/",
    element: <Outlet />, // Renders nested child routes
    children: [
      { index: true, element: <Navigate to="/login" replace /> }, // Redirects to /login for the root path
      ...authRoutes, // Child routes from authenticationRouter
      ...participantRoutes, // Child routes from participantRouter
      ...appRoutes, // Child routes from appRouter
      ...adminAndSalesAccountsDetailsRoutes,  // Child routes from salespersonDetailsRouter
      ...companyRoutes, // Child routes from companyRouter
      { path: "*", element: <Error404 /> }, // Renders Error404 component for any unmatched paths
    ],
  },
];
