import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { clearparticipantLoginData } from "../../redux/slice/participantLoginSlice";
import { ParticipantLoginDataSelector } from "../../redux/selectors/participantLoginSelector";
import { LoginDataSelector } from "../../redux/selectors/loginSelector";
import { clearloginData } from "../../redux/slice/loginSlice";

import Logo from '../../assets/styles/images/logo.png';
import OpenSVG from "../../assets/styles/images/open.svg";
import CloseSVG from "../../assets/styles/images/close.svg";
import IconMail from "../../assets/styles/images/icon-mail.svg";
import IconAccount from "../../assets/styles/images/icon-account.svg";
import IconAdmin from "../../assets/styles/images/icon-admin.svg";

const ParticipantHeader = () => {

  const participantLoginData = useSelector(ParticipantLoginDataSelector);
  const adminLoginData = useSelector(LoginDataSelector);
  const dropdownRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const menuIcon = document.getElementById('menu-icon');
  const menu = document.getElementById('menu');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contactUsLink = "https://faq-coach-a-3minutes.zendesk.com/hc/ja";
  
  //Navigate to login if not logged as admin and navigate to workshop page if already logged as admin
  const handleAdminIconClick = () => {
    if (adminLoginData?.access_token)
      navigate("/companies/" + participantLoginData?.user?.company_id + "/workshops")
    else
      navigate("/login");
  };
  //navigate to contact page
  const handleMailIconClick = () => {
    window.location.href = contactUsLink;
  };

  // Click outside the dropdown, close it
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  // Open and close the account dropdown based on isDropdownOpen
  const handleDropDownClick = () => {
    if (!isDropdownOpen) {
      setIsDropdownOpen(true);
    } else {
      setIsDropdownOpen(false);
    }
  }

  //Close the navbar when click the menu item
  const handleMenuItemClick = () => {
    closeMenu();
  };

  //opens navbar menu items
  const openMenu = useCallback(() => {
    setIsMenuOpen(true);
    if (menu) {
      var imgElement = document.createElement('img');
      imgElement.src = CloseSVG;
      imgElement.alt = 'close-icon';
      menuIcon.innerHTML = '';
      menuIcon.appendChild(imgElement);
    }
  }, [menu, menuIcon]);

  //close navbar menu items
  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
    var imgElement = document.createElement('img');
    imgElement.src = OpenSVG;
    imgElement.width = 20;
    imgElement.height = 20;
    menuIcon.innerHTML = '';
    menuIcon.appendChild(imgElement);
  }, [menuIcon]);

  // Open and close the navbar based on isMenuOpen
  const handleMenuIconClick = useCallback(() => {
    if (!isMenuOpen) {
      openMenu();
    } else {
      closeMenu();
    }
  }, [isMenuOpen, openMenu, closeMenu]);

  //clear redux and local storage on logout and navigation to participant login page
  const logout = () => {
    //clearReducers(dispatch);
    dispatch(clearparticipantLoginData());
    //persistor.purge();
    localStorage.removeItem("participant_token")
    if (participantLoginData?.user?.is_ca || participantLoginData?.user?.is_secretariat 
        || participantLoginData?.user?.is_salesperson) {
      localStorage?.removeItem("admin_token")
      localStorage.removeItem("company_id")
      localStorage.removeItem("authority")
      dispatch(clearloginData());
    }
    navigate("/participants/login");
    handleMenuItemClick();
    };

  const onChangePassword = () => {
    navigate("/participants/change-password");
  }

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleMenuIconClick, participantLoginData]);

  return (

    <header >
      <div className="container">
        <div className="row align-items-center header_height" >
          <div className="position-inherit z-index-1000">
            <Link to={"/participants/home"}><img src={Logo} alt='' /></Link>
          </div>
          <div className="position-inherit ml-auto">
            <div className="d-flex">
              <div className="navbars reponsiverev">
                <div className="menu-icon" id="menu-icon" data-testid="menu-icon" onClick={handleMenuIconClick}>
                  <img src={OpenSVG} style={{ "width": "20px" }} alt='' data-testid="OpenSVG"/>
                </div>
                <div className='top_links menu_text'>メニュー</div>
                {isMenuOpen && (<div className="menu" id="menu" data-testid="menu" style={{ "animation": "slideIn 0.5s ease-in-out", "display": "flex" }}>
                  {(participantLoginData?.user?.is_secretariat || participantLoginData?.user?.is_ca || participantLoginData?.user?.is_salesperson) === true && (
                    <Link to={""} onClick={handleAdminIconClick}>
                      事務局画面
                    </Link>)}
                    <Link to={contactUsLink} style={{display:"none"}}>
                      お問い合わせ
                    </Link>
                    <Link to={"/participants/change-password"} onClick={handleMenuItemClick} data-testid="password">
                      パスワード設定
                    </Link>
                    <Link to={"/participants/login"} onClick={logout} data-testid="logout">
                      ログアウト
                    </Link>
                </div>)}
              </div>
              {(participantLoginData?.user?.is_secretariat || participantLoginData?.user?.is_ca || participantLoginData?.user?.is_salesperson) === true && (
                <div className="text-center reponsivemenu pr-4">
                  <div className='icon-background' onClick={handleAdminIconClick} data-testid="admin-click"> <div><img src={IconAdmin} alt='admin-icon' /></div>
                    <div><span className="top_links">事務局画面</span></div>
                  </div>
                </div>)}
              <div className="text-center reponsivemenu pr-4" style={{display:"none"}}>
                <div className='icon-background' onClick={handleMailIconClick} data-testid="mailIconClick">
                  <div><img src={IconMail} alt='' /></div>
                  <div><span className="top_links">お問い合わせ</span></div>
                </div>
              </div>
              <div className="text-center reponsivemenu pr-4 marginRight_-25px">
                <div className='icon-background' ref={dropdownRef} onClick={handleDropDownClick} data-testid="dropDownClick">
                  <div className="dropdown">
                    <div className="" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" >
                      <div><img src={IconAccount} alt='' /></div>
                      <div><span className="top_links">アカウント</span></div>
                    </div>
                    <ul className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}
                        aria-labelledby="dropdownMenuButton1" 
                        style={{ left: isDropdownOpen ? '-71px' : 'auto' }}
                        data-testid="dropDownMenu">
                      <li><Link className="dropdown-item" to={'/participants/change-password'} onClick={onChangePassword} data-testid="change-password">パスワード設定</Link></li>
                      <li><Link className="dropdown-item" to={"/participants/login"} onClick={logout}>ログアウト</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

  )
}

export default ParticipantHeader
