import React from "react";
import { Navigate } from "react-router-dom";
import AuthLayout from "../layouts/authLayout";
import RegisterLayout from "../layouts/registerLayout";

const Login = React.lazy(() => import("../pages/authentication/login/login"));
const Register = React.lazy(() =>
  import("../pages/authentication/register/register")
);
const RegisterConfirm = React.lazy(() =>
  import("../pages/authentication/register/registerConfirm")
);
const RegisterSuccess = React.lazy(() =>
  import("../pages/authentication/register/registerSuccess")
);

const ForgotPassword = React.lazy(() =>
  import("../pages/authentication/forgotPassword/forgotPassword")
);

const ResetPassword = React.lazy(() =>
  import("../pages/authentication/resetPassword/resetPassword")
);

const ResetPasswordSuccess = React.lazy(() =>
  import("../pages/authentication/resetPassword/resetPasswordSuccess")
);

const SetPassword =React.lazy(() => 
  import("../pages/authentication/userRegisterPassword/userSetPassword"))

const SetPasswordSuccess = React.lazy( () => 
  import("../pages/authentication/userRegisterPassword/setPasswordSuccess")
)

/**
 * Array of route objects defining the routes for the authentication-related pages.
 */

export const authRoutes = [
  {
    path: "", // The base path for the authentication routes
    element: <AuthLayout />, // Renders the AuthLayout component for all child routes
    children: [
      { index: true, element: <Navigate to="/login" replace /> }, // Redirects to /login for the root path
      {
        path: "login",
        element: <Login />, // Renders the Login component for the /login path
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/reset-password/",
        element: <ResetPassword />,
      },
      {
        path: "/reset-password/success",
        element: <ResetPasswordSuccess />,
      },
      {
        path: "/set-password",
        element: <SetPassword/>,
      },
      {
        path: "/set-password/success",
        element: <SetPasswordSuccess />,
      },
    ],
  },
  {
    path: "", // The base path for the authentication routes
    element: <RegisterLayout />, // Renders the AuthLayout component for all child routes
    children: [
      {
        path: "/companies/:uuid/workshops/:workshop_uuid/admins/register",
        element: <Register />, // Renders the Register component for the /companies/:uuid/workshop-admins/register path
      },
      {
        path: "/companies/:uuid/workshops/:workshop_uuid/admins/register-confirm",
        element: <RegisterConfirm />, // Renders the RegisterConfirm component for the /companies/:uuid/workshop-admins/register-confirm path
      },
      {
        path: "/companies/:uuid/workshops/:workshop_uuid/admins/register-success",
        element: <RegisterSuccess />, // Renders the RegisterSuccess component for the /companies/:uuid/workshop-admins/register-success path
      },
    ]
  }
];
